import axios from 'axios'
import router from '../router'
// import VueCookies from 'vue-cookies'
// console.log(VueCookies.get("token"))

//拦截器----start
axios.interceptors.request.use(config => {
    let token = localStorage.Authorization;
    if (token) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
        config.headers.Authorization = token;
    }
    // config.headers['Access-Control-Allow-Origin'] = 'https://h5.yaojian.xusos.com'
    return config
}, error => {
    return Promise.reject(error)
})
//拦截器----end
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    let code=response.data.code
    let msg=response.data.msg
    if(code==401||code==4031||code==4032){
        router.push("/login?msg="+msg)
    }
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

let baseurl = "https://zzd.yaojian.xusos.com/api" // 'https://ydzk.scjgj.jinhua.gov.cn/zzd' //"https://zzd.yaojian.xusos.com/api"
if (window.location.href.indexOf('yyfb') == -1) {
    baseurl = "/api"
}
let config = {
    params:{},
    headers: {
        "Access-Control-Allow-Origin": "*", //解决cors头问题
        "Access-Control-Allow-Credentials": "true", //解决session问题
      //  "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8", //将表单数据传递转化为form-data类型
        "Content-Type": "application/json", //json提交
        Authorization: localStorage.Authorization,
    },
    // transformRequest:[function (data,headers){
    //     headers['Authorization']=localStorage.Authorization  //解决必须刷新一次才生效
    // }],
    withCredentials: false,   //此处不使用，采用nginx 时，post接收不了数据
    timeout: 30000 // request timeout
};
// let transformRequest=[function (data,headers){
//     headers['Authorization']=localStorage.Authorization  //解决必须刷新一次才生效
// }]
export function sms(phone) {
    let rs=axios.get(baseurl+'/member/sms/code',{
        params: {
            phone: phone
        }})
    return rs
}
export function getLogin(data) {
    let rs=axios.post(baseurl+'/login',data)
    return rs
}
export function getLogout() {
    let rs=axios.get(baseurl+'/logout',config)
    return rs
}


export function getUser() {
    //config.transformRequest=transformRequest
    let rs=axios.get(baseurl+'/system/user/info',config)
    return rs
}
export function getDetail(data) {
    config.params=data
    let rs=axios.get(baseurl+'/regulatory/task/detail',config)
    return rs
}
export function getNotice(data) {
    config.params=data
    let rs=axios.get(baseurl+'/regulatory/notice/page',config)
    return rs
}
export function getNoticeDetail(data) {
    config.params=data
    let rs=axios.get(baseurl+'/regulatory/notice/detail',config)
    return rs
}
export function getTask(data) {
    config.params=data
    let rs=axios.get(baseurl+'/regulatory/task/page',config)
    return rs
}
export function getTaskDetail(data) {
    config.params=data
    let rs=axios.get(baseurl+'/regulatory/task/detail',config)
    return rs
}
export function getXiafa(data) {
    delete config.transformRequest
    let rs=axios.post(baseurl+'/regulatory/task/lssued',data,config)
    return rs
}
export function getScore(data) {
    config.params=data
    let rs=axios.get(baseurl+'/regulatory/score/list',config)
    return rs
}
export function getMember(data) {
    config.params=data
    let rs=axios.get(baseurl+'/regulatory/member/list',config)
    return rs
}
export function getMemberAdd(data) {
    delete config.transformRequest
    let rs=axios.post(baseurl+'/regulatory/member/add',data,config)
    return rs
}
export function getMemberEdit(data) {
    delete config.transformRequest
    let rs=axios.post(baseurl+'/regulatory/member/update',data,config)
    return rs
}
export function getMemberDel(id) {
    let rs=axios.delete(baseurl+'/regulatory/member/delete/'+id,config)
    return rs
}

export function getTaskOk(data) {
    delete config.transformRequest
    let rs=axios.post(baseurl+'/regulatory/task/submit',data,config)
    return rs
}

export function getUpload(data) {
    delete config.transformRequest
    let rs=axios.post(baseurl+'/upload',data,config)
    return rs
}

export function zzdLogin(param) {
  config.params = param
  let rs=axios.get(baseurl+'/system/user/zzd/login',config)
  return rs
}

export function zzdBind(data) {
  delete config.transformRequest
  let rs=axios.post(baseurl+'/system/user/zzd/bind', data,config)
  return rs
}

/** 任务选项类型信息 */
export function taskTypeInfoApi(param) {
  config.params = param
  let rs=axios.get(baseurl+'/regulatory/score/type',config)
  return rs
}

//检查项列表
export function scoreList() {
    let rs=axios.get(baseurl+'/regulatory/score/list',config)
    return rs
}
export function taskList(data) {
    config.params=null
    let rs=axios.post(baseurl+'/regulatoryTaskRecord/list',data,config)
    return rs
}

/**
 * 监管所店铺
 * @param {*} data 
 * @returns 
 */
export function storeList(data) {
    delete config.transformRequest
    let rs=axios.post(baseurl+'/regulatory/task/store/list', data, config)
    return rs
}

/**
 * 创建任务
 * @param {*} data 
 */
export function createTaskApi(data) {
    delete config.transformRequest
    let rs=axios.post(baseurl+'/regulatory/task/create', data, config)
    return rs
}

/**
 * 监管档案（企业风险）
 * @param {*} data 
 */
export function storeLevelListApi(data) {
    delete config.transformRequest
    let rs=axios.post(baseurl+'/storeLevel/regulatory/store/list', data, config)
    return rs
}
export function taskAll(param) {
    config.params = param
    let rs=axios.get(baseurl+'/regulatory/task/all/page',config)
    return rs
}

/**
 * 责令整改详情
 * @param {*} params 
 */
export function disposalDetailApi(params) {
    config.params = params
    let rs=axios.get(baseurl+'/disposal/detail',config)
    return rs
}

/**
 * 待审核责令整改
 * @param
 */
export function disposalListApi(param) {
    config.params = param
    let rs=axios.get(baseurl+'/auth/data/disposal/page',config)
    return rs;
}

/**
 * 责令整改审核通过
 * @param {*} param 
 * @returns 
 */
export function disposalPassApi(param) {
    config.params = param
    let rs=axios.get(baseurl+'/auth/data/disposal/pass',config)
    return rs;
}

/**
 * 责令整改审核拒绝
 * @param {*} param 
 * @returns 
 */
export function disposalRejectApi(param) {
    config.params = param
    let rs=axios.get(baseurl+'/auth/data/disposal/reject',config)
    return rs;
}

export function getType(id){
    let arr=[]
    arr[10]="有因检查"
    arr[20]="专项检查"
    arr[30]="飞行检查"
    arr[40]="亚运检查"
    return arr[id];
}
export function getStatus(id){
    let arr=[]
    arr[10]="待下发"
    arr[20]="待检查"
    arr[30]="已完成"
    return arr[id];
}
